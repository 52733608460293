import { BULLET_GRAPH_COLORS, VUETIFY_COLORS as DEFAULT_VUETIFY_COLORS, TIMESERIES_COLORS } from '../default/colors.js'
import { VuetifyColors } from '../types.js'

const VUETIFY_COLORS = {
  ...DEFAULT_VUETIFY_COLORS,
  anchor: '#000000',
  primary: {
    base: '#808080',
    darken2: '#000000',
    darken4: '#000000',
    lighten2: '#B3B3B3',
    lighten3: '#D9D9D9',
    lighten4: '#D9D9D9'
  }
} as VuetifyColors

const COCKPIT_NUDGE_GRADIENT = [
  '#3d3d3d',
  '#3d3d3d',
  '#3d3d3d'
] as string[]

export { BULLET_GRAPH_COLORS, COCKPIT_NUDGE_GRADIENT, TIMESERIES_COLORS, VUETIFY_COLORS }
