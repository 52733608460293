import { Texts } from '../types.js'
import TEXTS from '../default/texts.js'

export default {
  ...TEXTS,
  appName: 'STRABAG',
  companyName: 'STRABAG',
  emailSupport: 'team.eco2state@strabag-pfs.com',
  phoneSupport: null,
} as Texts
